import React from "react"
import styled from "styled-components"
import TeamMember from "../team/member/member"
import { graphql, useStaticQuery } from "gatsby"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const TeamListingsAll = ({ introTitle, introContent }) => {
  const { allWpMember } = useStaticQuery(TEAM_LIST_ALL_QUERY)

  return (
    <WorkListWrapper>
      <FirstBlock>
        <h3>{introTitle}</h3>
        <p dangerouslySetInnerHTML={{ __html: introContent }} />
      </FirstBlock>
      {allWpMember.edges.map((item) => (
        <TeamMember item={item.node} key={item.node.id} />
      ))}
    </WorkListWrapper>
  )
}
export default TeamListingsAll

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WorkListWrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 60px auto;
  align-content: baseline;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    margin: ${(props) => props.theme.gutter};
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`
const FirstBlock = styled.div`
  text-align: left;
  h3 {
    margin-bottom: 30px;
    color: ${(props) => props.theme.green};
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const TEAM_LIST_ALL_QUERY = graphql`
  query TeamListAllContent {
    allWpMember(sort: { order: ASC, fields: date }) {
      edges {
        node {
          id
          title
          slug
          acfTeam {
            quote {
              quote
              author
            }
            education
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            preferredAreasOfPractice
          }
        }
      }
    }
  }
`
